
export default [
   
  { header: 'Trend Management',},
  {
    title: 'Trend Management',
    route: 'pamis-trend-home',
    icon: 'SomeIcon',
  },
 


]


export default [
   
  { header: 'Personnel Management',},
  {
    title: 'Personnel',
    route: 'pamis-personnel',
    icon: 'SomeIcon',
  },
 


]

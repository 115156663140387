export default [

  { header: 'Organisation Management' },
  {
    title: 'Region',
    route: 'pamis-organisation-region-list',
    icon: 'SomeIcon',
  },
  {
    title: 'Country',
    route: 'pamis-organisation-country-list',
    icon: 'SomeIcon',
  },
  {
    title: 'Company',
    route: 'pamis-organisation-company',
    icon: 'SomeIcon',
  },
  {
    title: 'Site',
    route: 'pamis-organisation-site',
    icon: 'SomeIcon',
  },
  {
    title: 'Workcenter',
    route: 'pamis-organisation-workcenter',
    icon: 'SomeIcon',
  },
  {
    title: 'Production Target',
    route: 'pamis-organisation-productionTarget',
    icon: 'SomeIcon',
  },
  {
    title: 'Production Location Detail',
    route: 'pamis-organisation-productionLocationDetail',
    icon: 'SomeIcon',
  },
]

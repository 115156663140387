
export default [
  { header: 'Device Management',},
  
  {
    title: 'Device',
    route: 'pamis-device',
    icon: 'SomeIcon',
  }
]

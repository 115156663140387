<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notReadCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        v-on:click="readAllNotification"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          v-if="notReadCount>0"
          pill
          variant="light-primary"
        >
          {{notReadCount}} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notificationList.length > 0" 
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
    <div v-for="notification in notificationList"
      :key="notification.id">
      <b-link :key="notification.id"
      >
        <b-media :class="!notification.isRead ? 'blinkClass' : ''">
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </div>
      <!-- System Notifications -->

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <!--<li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li>-->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getMessaging, onMessage } from "firebase/messaging";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  methods: {
    readAllNotification(){
      var cnt = this.notificationList.filter(x=>x.isRead == false).length;

      if (cnt > 0) {
        this.ReadAllNotifications();

        setTimeout(() => {
          this.fetchNotifications();
        }, 3000)
      }
    },
    fetchNotifications() {
      axios
        .get('nh/Notification/GetNotifications?ApplicationId=' + this.applicationId)
        .then(response =>{
          var list = response.data;
          var cnt = 0;
          var notifList = []

          if (list.length > 0) {
            list.forEach(element => {
              var data = {
                title: element.title,
                subtitle: element.message,
                type: 'light-danger',
                icon: 'AlertTriangleIcon',
                isRead : element.isRead,
                id:cnt
              }

              notifList.push(data);
              cnt++;
            });
          }

          this.notificationList = notifList;
          this.notificationList.sort((a, b) => Number(b.id) - Number(a.id));
          this.notReadCount = notifList.filter(x=>x.isRead == false).length;
        })
    },
    ReadAllNotifications() {
      return new Promise((resolve, reject) => {
        axios
          .patch('nh/Notification?ApplicationId=' + this.applicationId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setNotReadCount(){
      Notification.requestPermission().then((permission) => {
         if (permission === 'granted') {
          const messaging = getMessaging();
          onMessage(messaging, (payload) => {
            var userData= localStorage.getItem('userData');
            if (userData != null) {
              this.fetchNotifications();
              console.log('Message received. ', payload);
              this.notification(payload.notification.title, payload.notification.body);
            } 
          });
        }
      });
    },
    notification(title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'BellIcon',
          variant: 'success',
          text: message,
        },
      })
    },
    pollNotifications () {
      this.polling = setInterval(() => {
        this.fetchNotifications();
      }, 60000)
	  }
  },
  created() {
    this.fetchNotifications();
    this.pollNotifications();
    this.setNotReadCount();
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  data() {
    return {
      notificationList: [],
      notReadCount :0,
      applicationId :1,
      polling : null
    }
  },
  props: {
    notifList: [],
  },
  setup() {

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
.blinkClass{
  background-color: #f2d3db;
}

</style>

export default [
  { header: 'Workcenter Shift Management', },
  {
    title: 'Shift',
    route: 'pamis-shift-home',
    icon: 'SomeIcon',
  },
  {
    title: 'Workcenter Shift',
    route: 'pamis-workcenter-shift-home',
    icon: 'SomeIcon',
  },
]
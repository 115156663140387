
export default [

    { header: 'Unit Management', },
    {
        title: 'Unit Management',
        route: 'pamis-unit',
        icon: 'SomeIcon',
    },
]

export default [
  { header: 'Breakdown Management' },
  {
    title: 'Breakdown',
    route: 'pamis-breakdown',
    icon: 'SomeIcon',
  },
  {
    title: 'Breakdown Type Management',
    route: 'pamis-breakdown-type',
    icon: 'SomeIcon',
  },
]


export default [
    { header: 'Spec Parameters',},
    
    {
      title: 'Spec',
      route: 'pamis-spec-parameters',
      icon: 'SomeIcon',
    },
    {
      title: 'Parameters',
      route: 'pamis-parameter',
      icon: 'SomeIcon',
    }
  ]
  

export default [

  { header: 'Workorder Management', },
  {
    title: 'Workorder Management',
    route: 'pamis-workorder-home',
    icon: 'SomeIcon',
  },
  {
    title: 'Product',
    route: 'pamis-product',
    icon: 'SomeIcon',
  },
  {
    title: 'Mould',
    route: 'pamis-mould',
    icon: 'SomeIcon',
  }


]

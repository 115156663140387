import breakdown from './breakdown'
import device from './device'
import organisation from './organisation'
import personnel from './personnel'
import production from './production'
import trend from './trend'
import workorder from './work-order'
import workcenter from './workcenter-shift'
import specParameters from './spec-parameters'
import unit from './unit'

export default [
  ...breakdown,
  ...device,
  ...organisation,
  ...personnel,
  ...production,
  ...trend,
  ...workorder,
  ...workcenter,
  ...specParameters,
  ...unit,
]


export default [
   
  { header: 'Production Management',},
  {
    title: 'Production Result Type',
    route: 'pamis-production-result-type',
    icon: 'SomeIcon',
  },
  {
    title: 'Production Tag',
    route: 'pamis-production-tag',
    icon: 'SomeIcon',
  },
]